<template>
	<div class="MyNFTList">
		<my-nft-list-nav :index="current_index" :list="navList" @click="_navItemTap"/>
		<div class="MyNFTList-box">
			<div class="d-flex justify-center pt10" v-if="list.length === 0">
				<div class="MyNFTList-nodata">{{ $t('You currently have no cards in this category. You can') }}
					<router-link to="/app/Game/BuyBox">{{ $t('buy boxes') }}</router-link>
					{{ $t('and get your NTF cards.') }}
				</div>
			</div>
			<div class="MyNFTList-card__box">
				<div class="MyNFTList-card__item" v-for="(item, index) in list" :key="index" @click="_goDetails(item)">
					<img class="card-img" src="@/assets/open-bg.png" alt="">
					<img class="content-img" :src="getCardImgUrl(item.key)" alt="">
					<div class="MyNFTList-card__content">
						<div class="MyNFTList-card__head">
							<span class="MyNFTList-card__number">{{ item.num }}</span>
							<img class="combination-img" src="@/assets/combination_tag.png" alt="" v-show="card_data[item.key].combined_list.length > 0">
						</div>
						<div class="MyNFTList-card__name">
							<div>{{ card_data[item.key].zhHans }}</div>
							<div>{{ card_data[item.key].en }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import MyNftListNav from '@/views/Game/MyNFT/MyNftListNav'
import { myCards } from '@/utils/cardFactory'
import eventBus from '@/utils/eventBus'
import CardLis from '@/data/card'
import { getCardImgUrl } from '@/utils/tools'
import { SessionStorage } from 'storage-manager-js'

export default {
	name: 'MyNFTList',
	components: { MyNftListNav },
	data () {
		return {
			card_data: CardLis,
			navList: [],
			card_list_obj: {},
			current_index: 0,
		}
	},
	computed: {
		...mapState(['ADDRESS', 'card_nav_list']),
		list () {
			return this.card_list_obj[this.navList[this.current_index].type] || []
		},
	},
	created () {
		const { index } = this.$route.query
		if (index) {
			this.current_index = Number(index)
		}
		this.navList = JSON.parse(JSON.stringify(this.card_nav_list))
	},
	mounted () {
		if (this.ADDRESS) {
			this.myCards()
		}
		// 添加 eventBus 监听器
		eventBus.$on('connect-event', (res) => {
			this.myCards()
		})
	},
	methods: {
		...mapMutations(['setMyCardList']),
		_navItemTap ({ index }) {
			this.current_index = index
		},
		getCardImgUrl (filename, type) {
			return getCardImgUrl(filename, type)
		},
		_goDetails (query) {
			SessionStorage.set('CARD_INFO', query)
			this.$router.push({ path: '/app/Game/MyNFT/NftDetails', query: { index: String(this.current_index) } })
		},
		async myCards () {
			const res = await myCards(this.ADDRESS, 10000)
			this.card_list_obj = {}
			this.navList.map(item => {
				this.card_list_obj[item.type] = []
			})
			res.map(item => {
				const keys = item.key.split('-')
				this.navList.map((nav, i) => {
					if (nav.type === Number(keys[0])) {
						this.navList[i].value += item.num
					}
				})
				this.navList[0].value += item.num
				this.card_list_obj[keys[0]].push(item)
			})
			SessionStorage.set('NFT_NAV_LIST', this.navList)
			this.card_list_obj[0] = res
			this.setMyCardList(res)
			SessionStorage.set('MY_CARDS', res)
		},
	},
}
</script>

<style lang="scss" scoped></style>
